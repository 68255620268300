var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app flex-row align-items-center"
  }, [_c('div', {
    staticClass: "container mt-3"
  }, [_c('router-link', {
    staticClass: "btn btn-sm btn-dark",
    attrs: {
      "size": "sm",
      "to": {
        path: "/settings/profile"
      }
    }
  }, [_c('em', {
    staticClass: "fa fa-backward btn-sm btn-dark"
  }), _vm._v(" Back ")]), _c('b-row', {
    staticClass: "row justify-content-center"
  }, [_c('b-col', {
    staticClass: "left mt-6 display-mobile",
    attrs: {
      "md": "6",
      "sm": "0"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.qrCode,
      "height": "600px",
      "width": "600px"
    }
  })]), _c('b-col', {
    staticClass: "right",
    attrs: {
      "md": "6"
    }
  }, [_c('b-card-group', [_c('b-card-body', {
    staticClass: "background-mobile"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "m-5",
    attrs: {
      "src": require("../../assets/img/gramedia_digital.png"),
      "alt": "admin@bootstrapmaster.com"
    }
  })])], 1), _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.actionSave.apply(null, arguments);
      }
    }
  }, [_c('h1', {
    staticClass: "h2 text-center"
  }, [_vm._v(" Digital Books Gramedia ")]), _c('h4', {
    staticClass: "text-center"
  }, [_vm._v(" New Authorize Device ")]), this.key ? _c('div', [_c('b-row', [_c('b-col', [_c('h6', {
    staticClass: "text-center my-3",
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("Do Not Give This Key to Others")])])], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "ml-5"
  }, [_c('wording-scan-barcode-vue')], 1)])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "text-center"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v("Save Secret Key")])], 1)])], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }