<template>
  <div class="app flex-row align-items-center">
    <div class="container mt-3">
      <router-link class="btn btn-sm btn-dark"  size="sm"  :to="{ path: `/settings/profile`}">
        <em class="fa fa-backward btn-sm btn-dark "></em> Back
      </router-link>
      <b-row class="row justify-content-center">
        <b-col md="6" sm="0" class="left mt-6 display-mobile">
          <img
            :src="qrCode"
            height="600px"
            width="600px"
          />
        </b-col>
        <b-col md="6" class="right">
          <b-card-group>
              <b-card-body class="background-mobile">
                <b-row align-v="center">
                  <b-col class="text-center">
                  <img
                    src="../../assets/img/gramedia_digital.png"
                    alt="admin@bootstrapmaster.com"
                    class="m-5"
                  />
                  </b-col>
                </b-row>
                <b-form @submit.prevent="actionSave">
                  <h1 class="h2 text-center">  Digital Books Gramedia </h1>
                  <h4 class="text-center">  New Authorize Device </h4>
                  <div v-if='(this.key)'>
                    <b-row>
                      <b-col>
                        <h6 class="text-center my-3" style="color:red">Do Not Give This Key to Others</h6>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <div class="ml-5">
                          <wording-scan-barcode-vue></wording-scan-barcode-vue>
                        </div>
                      </b-col>
                    </b-row>
                    
                  </div>
                  <div class="text-center">
                    <b-button variant='primary' type='submit'>Save Secret Key</b-button>
                  </div>
                </b-form>
              </b-card-body>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import wordingScanBarcodeVue from '../../components/auth/wording-scan-barcode.vue';
export default {
  name: "twoFactor",
  components: {
    wordingScanBarcodeVue,
  },
  data() {
    return {
      form: {key:null,},
      isActive: false,
    };
  },created(){
    this.generateSecretKey();
    this.setSecretKey();
    
  },
  computed: {
    ...mapState({
      success: (state) => state.profile.isSuccess,
      message: (state) => state.profile.successMessage,
      isLoading: (state) => state.profile.isLoading,
      isError: (state) =>state.profile.isError,
      key : (state) => state.profile.key,
      qrCode: (state) =>state.profile.qrCode,
    }),
  },
  watch: {
    $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'Two Factor Authentication | Content Management System Ebooks Gramedia.com';
        }
    },
    key: function(){
      this.setSecretKey();
    },
    isError: function(){
      if(this.isError){
        if(this.message){
          this.showAlert({
          success: false,
          message: this.message,
          title: "Error Create Access",
          });
        }else{
          this.showAlert({
          success: false,
          message: "Error Create Access",
          title: "Error Create Access",
          });
        }
      }
      this.setSecretKey();
    },
    success: function (){
      if(this.success){
          this.showAlert({
          success: true,
          message: this.message,
          title: "Success Update Secret Key",
          });
          this.$router.push({
            path: `/settings/profile`,
          })
        }
      }
    },
  methods: {
    ...mapActions("profile", ["generateSecretKey","saveSecretKey"]),
    actionSave() {

      //show some alert first
      this.$swal
        .fire({
          title: "Are You Sure You Have Scan the Barcode?",
          text: `This Action Cannot be Rollback`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let payload = {
              secretKey: this.key,
            };
          this.saveSecretKey(payload);
        }
      });
    },
    showAlert(data) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon: data.success ? "success" : "error",
        title: data.message,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },createNewAuth(e) {
      e.preventDefault();
          this.$router.push({
          path: `/newAuth/${this.token}`,
        });
    },
    setSecretKey() {
      this.form.key = this.key;
    },
  },
  mounted() {
   
  },
};
</script>
<style lang="css" scoped>
.center {
  width: 500px;
  height: 400px;
  top: 20%;
  position: absolute;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
}
.form-control {
  border: 0;
  border-bottom: 1px solid #000;
  outline: none;
}
input.form-control:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
@media (min-width: 360px) and (max-width: 768px) {
  .background-mobile {
    background-color: #fff;
  }
  .display-mobile {
    display: none;
  }
}

</style>
